.handwritten {
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: var(--color-text-body);
  text-align: center;
  max-width: 450px;
}

h1 {
  margin: 1rem 0;
  text-align: left;
  font-weight:normal;
}

h2 {
  margin: 1rem 0;
  text-align: left;
  font-weight:normal;
}

p {
  margin: 1rem 0;
  text-align: justify;
  word-spacing: 0.3rem;
  line-height: 1.5rem;
}

.formatted-text {
  white-space: pre-line; /* Preserves new lines and collapses multiple spaces */
}

.code-block {
  font-family: 'Courier New', Courier, monospace;
  background-color: var(--color-background-light);
  
  font-size: 0.7rem;
  padding: 0.7rem;
  border-radius: 0.5rem;
  margin: 1rem 0;
  overflow: auto;
  word-wrap: break-word;
}

@media only screen and (max-width: 960px) {
  .snowflakes {
    width: 100%;
    height: 110%;
    top: -180px;
  }
 }

@media only screen and (min-width: 960px) {
  .snowflakes {
    width: 50%;
    height: 110%;
    top: -90px;
  }
}

.snowflakes {
  position: absolute;

  left: 0;
  overflow: hidden; /* Ensure the snowflakes do not overflow the parent div */
  }

i, i:after, i:before { background: white; }

i {
  display: inline-block;
  animation: snowflakes 3s linear 2s 20;
  position: relative;
}

i:after, i:before {
  height: 100%;
  width: 100%;
  content: ".";
  position: absolute;
  top: 0px;
  left: 0px;
  transform: rotate(120deg);
}

i:before { transform: rotate(240deg); }

@keyframes snowflakes {
  0% {
    transform: translate3d(0,0,0) rotate(0deg) scale(0.6);
  }
  100% {
    transform: translate3d(15px, 1800px, 0px) rotate(360deg) scale(0.6);
  };
}

.snowflakes i:nth-child(3n) {
  width: 16px; height: 4px;
  animation-duration: 4s;
  animation-iteration-count: 30;
  transform-origin: right -45px;
}

.snowflakes i:nth-child(3n+1) {
  width: 24px; height: 6px;
  animation-duration: 6s;
  animation-iteration-count: 45;
  transform-origin: right -30px;
}

.snowflakes i:nth-child(3n+2) {
  width: 32px; height: 8px;
  animation-duration: 8s;
  animation-iteration-count: 60;
  transform-origin: right -15px;
}

/* different delays so they don't all start at the same time */
.snowflakes i:nth-child(7n) {
  opacity:.3;
  animation-delay: 0s;
  animation-timing-function:ease-in;
}

.snowflakes i:nth-child(7n+1) {
  opacity:.4;
  animation-delay: 1s;
  animation-timing-function:ease-out;
}

.snowflakes i:nth-child(7n+2) {
  opacity:.5;
  animation-delay: 1.5s;
  animation-timing-function:linear;
}

.snowflakes i:nth-child(7n+3) {
  opacity:.6;
  animation-delay: 2s;
  animation-timing-function:ease-in;
}

.snowflakes i:nth-child(7n+4) {
  opacity:.7;
  animation-delay: 2.5s;
  animation-timing-function:linear;
}

.snowflakes i:nth-child(7n+5) {
  opacity:.8;
  animation-delay: 3s;
  animation-timing-function:ease-out;
}

.snowflakes i:nth-child(7n+6) {
  opacity:.9;
  animation-delay: 3.5s;
  animation-timing-function:ease-in;
}
