.form {
  width: 100%;
  max-width: 450px;
}

label {
    display: block;
    font-family: font_regular, sans-serif;
    font-size: 0.75rem;
    color: var(--color-text-body);
    margin-top: var(--spacing-1);
}

input {
    width: 100%;
    display: block;

    border: 1px solid #ccc;
    border-radius: var(--radius);
    font-family: font_regular, sans-serif;
    font-size: 0.75rem;
    padding: var(--spacing-1);
    margin-bottom: var(--spacing-2);
    box-shadow: 0px 0.5px 3px var(--color-box-shadow);
}

#addressSearch {
  margin-top: var(--spacing-3);
  border: 1px solid var(--color-box-shadow);
  box-shadow: 0px 0.5px 3px var(--color-orange-two);
}

#firstName {
    margin-bottom: 0;
}

#lastName {
    border-top: none;
    margin-top: 0;
}

#addressLine1 {
    margin-bottom: 0;
}
#addressLine2 {
    border-top: none;
    margin-top: 0;
}

#city {
    margin-bottom: 0;
}
#postalCode {
    border-top: none;
    margin-top: 0;
}

.submit {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-1);
    /*background-color: var(--color-orange);*/
    animation: color-change-3x 4s linear infinite alternate both;
    border: none;
    border-radius: var(--radius);
    font-family: font_regular, sans-serif;
    cursor: pointer;
    box-shadow: 0px 0.5px 3px var(--color-box-shadow);
}

.submit:hover {
    background-color: var(--color-orange);
    animation: none;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  align-items: end;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}