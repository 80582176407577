.container {
  display: flex;
  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;
  align-content: stretch;
  flex-wrap: wrap;
}

.side {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  overflow: hidden;
}

.column-background-1 {
  background: var(--background-gradient, var(--background-color));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.column-background-2 {
  background-color: white;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.75s ease-in-out;
}

.side-content {
  min-width: var(--mobile-phone-width);
  max-width: 450px;
  padding: 4rem 1rem;
  width: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 960px) {
  .side {
    width: 100%;
  }
 }
 
 
 @media only screen and (min-width: 960px) {
  .side {
    width: 50%;
    height: 100%;
  }
 }
 