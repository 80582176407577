.thank-you-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.checkmark-svg {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.checkmark {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  transition: stroke-dashoffset 0.8s ease;
}

.thank-you-text {
  font-size: 1.5rem;
  color: var(--color-text-header);
  font-weight: bold;
  text-align: center;
}

.textarea {
  display: block;
  font-family: font_regular, sans-serif;
  font-size: 0.9rem;
  color: var(--color-text-body);
  margin: var(--spacing-1);
  text-align: center;
}

.envelope {
  background-color: var(--color-background-light);
  display: flex;
  flex-direction: column;
  min-height: 200px;
  padding: var(--spacing-1);
  margin: var(--spacing-1);
  width: 100%;
  border: 0.6rem solid transparent;
  border-image: 16 repeating-linear-gradient(-45deg, red 0, red 1em, transparent 0, transparent 2em,
	              #58a 0, #58a 3em, transparent 0, transparent 4em);
}

.envelope-address {
  display: flex;
  text-align: center;
  font-size: 0.85rem;
  color: var(--color-text-primary);
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: wrap;
}