@font-face {
  font-family: 'font_regular';
  src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'font_light';
  src: url('../fonts/Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'font_bold';
  src: url('../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}


:root {
  --color-light-orange: #FFD180;
  --color-orange: #F37022;

  --color-orange-one: #FF815A;
  --color-orange-two: #FFAF5B;
  --color-orange-three: #FFDF5B;

  --color-text-body: #452121;

  --color-background-primary: #fcfcfc;
  --color-background-secondary: #643434;
  --color-background: var(--color-background-secondary);

  --background-gradient: linear-gradient(#ff815a, #ffaf5b, #ffdf5b);
  --background-color: #ff9e5a;
  --cover-color: #FFDF5E;

  --color-foreground: #ffffff;
  --color-border: #e6e6e6;
  --color-background-light: #e6e6e6;
  --color-text-header: #444444;

  --color-text-secondary: #717171;
  --color-text-invert: #ffffff;
  --color-text-link: #4ec3f5;

  --color-text: var(--color-text-invert);

  --color-box-shadow: rgba(0, 0, 0, 0.3);

  --spacing-0: 4px;
  --spacing-1: 8px;
  --spacing-2: 16px;
  --spacing-3: 24px;
  --spacing-4: 36px;
  --spacing-5: 64px;

  --radius: 4px;
  --radius-2: 8px;

  --boxshadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);

  --mobile-phone-width: 300px;
  --tablet-width: 768px;
}

@keyframes color-change-3x {
  0% {
    background: #ff815a;
  }
  50% {
    background: #ffaf5b;
  }
  100% {
    background: #ffdf5b;
  }
}

.background-color-change-3x {
	animation: color-change-3x 4s linear infinite alternate both;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

body,
html,
#root {
  font-size: 17px;
  height: 100%;
  min-width: var(--mobile-phone-width);
  font-family: font_regular, sans-serif;
}

#root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

h1 {
  font-size: 1.5rem;
}

.h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.25rem;
}

.h2 {
  font-size: 1.25rem;
}

p {
  font-size: 1rem;
}

.p {
  font-size: 1rem;
}