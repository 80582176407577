label {
  display: block;
  font-family: font_regular, sans-serif;
  font-size: 0.75rem;
  color: var(--color-text-body);
  margin-top: var(--spacing-1);
}

.centre {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
}

.shake-horizontal {
	animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

 @keyframes shake-horizontal {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
}


.error-message {
  color: var(--color-orange);
  font-size: 0.75rem;
  justify-content: center;
  height: 1rem;
}

input {
  width: 100%;
  display: block;

  border: 1px solid #ccc;
  border-radius: var(--radius);
  font-family: font_regular, sans-serif;
  font-size: 0.75rem;
  padding: var(--spacing-1);
  box-shadow: 0px 0.5px 3px var(--color-box-shadow);
}

